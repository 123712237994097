<template>
  <div class="home-page-container">
    <div class="home-page">
      <background-image />
      <a href="https://seamlesssource.com">
        <img :src="logoImg" width="180px" class="item-center" />
      </a>

      <p class="md-display-1 item-margin blue-text" id="tagline">
        {{ tagline }}
      </p>
      
      <div>
        <md-button
          class="login-button"
          :md-ripple="false"
          href="#/login"
          >Log In</md-button
        >
      </div>
      <div>
        <md-button
          class="register-button"
          href="#/register"
          >Sign Up</md-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import BackgroundImage from '../../assets/background-images/BackgroundImage.vue';
import * as conf from "../../conf.yml";

export default {
  components: { BackgroundImage },
  name: "Home",
  data: () => ({
    logoImg: require(`../../assets/seamless-source-banner.png`),
    appName: conf.appName,
    tagline: 'Seamless apparel sourcing solution',
  }),
};
</script>

<style lang="scss" scoped>
.home-page-container {
  display: flex;
  align-items: center;
  height: 100vh;
}

.home-page {
  text-align: center;
  margin: auto;
  width: fit-content;
}

.app-name {
  font-size: 64px;
  padding-bottom: 36px;
}
.item-center {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.item-margin {
  margin: 40px;
}
.item-margin-small {
  margin: 10px;
}
.blue-text {
  font-weight: 400;
  font-size: 19px;
  line-height: 24px;
  text-align: center;
  color: #442D65;
}
.login-button {
  width: 300px;
  height: 45px;
  background: #442D64;
  box-shadow: 0px 12px 14px rgba(91, 61, 135, 0.23);
  border-radius: 7px;
  color: #FFFFFF;
  font-weight: 600;
}

.register-button {
  width: 300px;
  height: 45px;
  background: #FFFFFF;
  box-shadow: 0px 13.5045px 25.8836px rgba(61, 77, 135, 0.1);
  border-radius: 7px;
  color: #442D64;
  font-weight: 600;
}
</style>
